<template>
    <b-icon icon="arrow-left-circle-fill" :variant="d_variant" @click="$emit('click')"></b-icon>
</template>
  
<script >
    export default {
        name:"NavigateBackIcon",
        props:{ variant: { type:String, default: 'secondary' }},
        data(){
            return {
                d_variant: this.variant,
            }
        },
    }
</script>
  